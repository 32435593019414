<template>
  <div v-if="isView">
    <div class="popup">
      <h2 class="popLogo"><img src="@/assets/img/main/main_toplogo.png" alt="VIKBET"></h2>
      <div class="section">
        <h3 class="popTitle">회원가입</h3>
        <div class="inpWrap">
          <div class="inp">
            <input v-model="signUpForm.userId" type="text" placeholder="아이디(영문 또는 영문+숫자 조합으로 최소 6자 이상, 최대 12자 이하)" >
            <p v-if="userIdMsg" style="color:orange; padding-top: 10px; padding-bottom: 0px;">{{ userIdMsg }}</p>
          </div>
          <div class="inp">
            <input v-model="signUpForm.nickname" type="text" placeholder="닉네임(한글 또는 한글+숫자 조합으로 최소 6자 이상, 최대 12자 이하)" >
            <p v-if="nicknameMsg" style="color:orange; padding-top: 10px; padding-bottom: 0px;">{{ nicknameMsg }}</p>
          </div>
          <div class="inp"><input v-model="signUpForm.userPw" type="password" placeholder="비밀번호(8자 이상)"></div>
          <div class="inp"><input v-model="signUpForm.userPw2" type="password" placeholder="비밀번호 확인"></div>
          <div class="inpSel">
            <div class="selBox">
              <label for="sel01">{{ signUpForm.bankName ? signUpForm.bankName : '선택' }}</label>
              <select id="sel01" v-model="signUpForm.bankName">
                <option v-for="(row, index) in bank_name" :key="'bankName' +index" :value="row.name">{{ row.name }}</option>
              </select>
            </div>
            <input type="text" placeholder="예금주" v-model="signUpForm.bankDepositor" style="color: #fff;">
          </div>
          <div class="inp"><input type="text" style="color: #fff;" placeholder="계좌번호 입력(- 없이 숫자만 입력)" v-model="signUpForm.bankAccount"></div>
          <div class="inp">
            <input type="text" placeholder="휴대폰 번호(- 없이 숫자만 입력)" v-model="signUpForm.contact">
<!--            <a href="#">인증</a>-->
          </div>
          <div class="inp"><input v-model="signUpForm.cashoutPw" type="password" placeholder="출금비밀번호" maxlength="6"></div>
<!--          <div class="inpBtn"><input type="text" placeholder="인증번호"><a href="#">확인</a></div>-->
          <div class="inp">
            <input type="text" placeholder="가입코드" v-model="signUpForm.code">
            <p v-if="codeMsg" style="color:orange; padding-top: 10px; padding-bottom: 0px;">{{ codeMsg }}</p>
          </div>
        </div>
<!--        <p class="infoTxt">추천인 아이디를 제외한 모든 항목은 필수 입력 사항 입니다.</p>-->
        <a v-if="!isProcess" @click="doSignup" class="btn type01">회원가입</a>
        <Loading v-else></Loading>

        <p class="infoTxt">이미 회원이라면? 로그인 후 이용해 주시기 바랍니다.</p>
        <a @click="closeModal('signup')" class="btn">로그인</a>
      </div>
      <a @click="closeModal('signup')" class="btnClose"><img src="@/assets/img/ico/popup_close.png" alt="닫기"></a>
    </div>
    <span class="bgPop"></span>
  </div>
</template>

<script>
import MSG from '@/contants/msg';
import Loading from '../components/Loading'
export default {
  name: "Signup",
  components: {
    Loading
  },
  data: function(){
    return {
      signUpForm: {
        userId: null,
        nickname: null,
        userPw: null,
        userPw2: null,
        contact: null,
        cashoutPw: null,
        bankDepositor: null,
        bankName: null,
        bankAccount: null,
        code: '',
        referrer: '',
      },
      infoAgree: false,
      idPass: false,
      codePass: false,
      nicknamePass: false,
      isProcess: false,
      userIdMsg: null, // 에러 메세지
      nicknameMsg: null, // 에러 메세지
      codeMsg: null, // 에러 메세지
    }
  },
  computed: {
    isView: function(){
      return this.$store.state.modals.signup
    },
    config: function(){
      return this.$store.state.setting.settings;
    },
    bank_name: function(){
      return this.$store.getters['BANK_SETTING']
    }
  },
  watch: {
    'signUpForm.userId': function(val){
      this.userId = val.replace(/[^a-zA-Z0-9]/gi, '')
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

      if(reg.exec(val)!==null){
        return this.signUpForm.userId = this.signUpForm.userId.slice(0,-1);
      }
    },
    isView: function(){
      this.isProcess = false;
    }
  },
  methods: {
    closeModal: function(){
      return this.$store.dispatch('INVISIBLE_ALL_MODALS')
    },
    doSignup: function(){
      // if (!this.infoAgree) return alert('개인정보 수집 및 이용동의를 체크 해주세요');
      if(confirm('가입하시겠습니까?')){
        let errMsg = '';
      if (this.signUpForm.userPw !== this.signUpForm.userPw2) errMsg = MSG.error.difPw
      if (!this.signUpForm.userId) errMsg = MSG.error.emptyId;
      if (!this.signUpForm.userPw) errMsg =  MSG.error.emptyPassword;
      if (!this.signUpForm.userPw2) errMsg = MSG.error.emptyPassword;
      if (this.signUpForm.userPw?.length <= 7) errMsg = MSG.error.shortPassword;
      if (!this.signUpForm.nickname) errMsg = MSG.error.emptyNick;
      if (!this.signUpForm.code) errMsg = MSG.error.emptyCode;
      if (!this.signUpForm.contact) errMsg = MSG.error.emptyContact;
      if (!this.signUpForm.bankName) errMsg = MSG.error.emptyBank;
      if (!this.signUpForm.bankDepositor) errMsg = MSG.error.emptyDepositor;
      if (!this.signUpForm.bankAccount) errMsg = MSG.error.emptyBankNum;
      if (!this.signUpForm.cashoutPw) errMsg =  MSG.error.emptyWithdrawalPw;
      if (errMsg !== '') {
        this.$store.state.modals.signup = false;
        return this.$store.dispatch('SHOW_ERR_MSG2', errMsg);
      }
      // if (!this.codePass) return alert('가입하실수 없는 코드입니다.');
      this.isProcess = true;
      return this.$store.dispatch('SIGNUP', this.signUpForm)
          .then(() => {
            this.$store.dispatch('SHOW_ERR_MSG2', MSG.success.signup);
          })
          .catch((error) => {
            this.$store.dispatch('SHOW_ERR_MSG2', error.response.data.error);
          })
      }
      
    },
  },
}
</script>

<style scoped>
.popLogo img {
  height: 50px;
}
</style>
