<template>
  <section v-if="!this.$store.state.page.isMobile" style="min-height: 800px;">
    <div class="new_page_tabpc" style="padding-left: 13%;">
      <router-link to="/sport/lsport/prematch" ><span>엘스포츠 유럽형</span></router-link>
      <router-link to="/sport/prematch" class="cho"><span>벳컨 유럽형</span></router-link>
    </div>
    <table width="100%" style="margin-top: 20px; width: 86%; margin-left:20px;">
      <tbody>
      <tr>
        <LeftSide :origin-list="originList" :filter-by-games="filterByGames" :is-loading="isLoading" :select-game="selectGame"></LeftSide>

        <!-- left -->
        <td width="80%" style="padding:0 10px 0 10px; min-width: 80%" valign="top" v-if="isLoading">
          <table v-if="!selectedGame" width="100%" valign="top" border="0" cellspacing="0" cellpadding="0" class="ng-isolate-scope">
            <tbody>
            <tr>
              <td width="100%">
                <!-- ngInclude: centerTab -->
                <div style="">
                  <div style="display: block;" id="clive" class="animate-bottom ">
                    <div class="game_center_wrap">
                      <div class="effect_tab_wrap">
                        <div class="effect_tab_table">
                          <div class="effect_row">
                            <Catogory :origin-list="originList" :filter-by-games="filterByGames"></Catogory>
                            <Date :date-cnt="dateCnt" :init-folder="initFolder" :payload="payload"></Date>
                          </div>
                        </div>
                      </div>

                      <div class="sports_wrap">

                        <table class="sports_title_table_1" cellpadding="0" cellspacing="0">
                          <tbody>
                          <tr>
<!--                            <td width="3%"></td>-->
                            <td width="8%">경기시각</td>
                            <td width="9%">종목</td>
                            <td width="10%">국가</td>
                            <td width="10%">리그</td>
                            <td>팀</td>
<!--                            <td width="5%" style="color: #e65454;">맥스</td>-->
                            <td width="7%">승</td>
                            <td width="7%">무</td>
                            <td width="7%">패</td>
                            <!-- ngIf: ISLG -->
                            <td width="6%" class="font_002">더보기</td>
                          </tr>
                          </tbody>
                        </table>
                        <template v-for="(game, index) in lists" v-if="lists">
                          <template v-if="game.isVisible === 'y'">
                            <table class="sports_table_1" cellpadding="0" cellspacing="0" :key="'gameIndex'+index">
                              <tbody>
                              <tr style="background-color: rgb(34, 38, 39);">
                                <!--                                <td class="sports_1" style="background-color: rgb(34, 38, 39);">-->
                                <!--                                  <a @click="addStaredGame(game)" style="color: #8a8c8d;">-->
                                <!--                                    <font-awesome-icon v-if="linkedGame.find(e => e.id === game.id)" icon="fa fa-star"/>-->
                                <!--                                    <font-awesome-icon v-else icon="fa-regular fa-star"/>-->
                                <!--                                  </a>-->
                                <!--                                </td>-->
                                <td class="sports_3_2" style="width: 8% !important;">{{ game.startTime | formatDate('MM-DD HH:mm') }}</td>
                                <td class="sports_2_2" style="width: 9% !important;">
                                  <img :src="game.sport.icon" style="margin-left: 10px; width:15px;">&nbsp;
                                  {{ game.sport | sportName }}
                                </td>
                                <td class="sports_4_2" style="width: 10% !important;">
                                  <img style="vertical-align:middle;width:14px;height:10px;margin-bottom:3px;margin-left:5px;" v-if="game.location.icon" :src="game.location.icon">&nbsp;
                                  {{ game.location | locationName }}
                                </td>
                                <td class="sports_4_2" style="width: 10% !important;">
                                  <img style="vertical-align:middle;width:14px;height:10px;margin-bottom:3px;margin-left:5px;" v-if="game.league.icon" :src="game.league.icon">&nbsp;
                                  {{ game.league | leagueName }}
                                </td>
                                <!-- <td class="sports_5_2" style="width: 10% !important;">
                                  {{ game.league | leagueName }}
                                </td> -->

                                <td class="sports_6_2">
                                  <table width="100%" cellpadding="0" cellspacing="0">
                                    <tbody>
                                    <tr href="#">
                                      <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                      <td class="pre_4_1_2">vs</td>
                                      <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <!--                                <td class="sports_6_4">-->
                                <!--                                  {{ game.league.maxAmount | amtKor }}-->
                                <!--                                </td>-->
                                <!-- 배당노출 -->
                                <template v-if="game.folders.length > 0 && (game.folders[0].isSuspended === 'y' || game.isSuspended === 'y' ||  game.folders[0].bets.find(b => Number(b.price) === 0))">
                                  <td class="european_7_3">
                                    <table width="100%">
                                      <tbody>
                                      <tr class="ng-hide">
                                      <tr>
                                        <td style="width:43%;">
                                          <i class="fa fa-fw" style="text-align:right;">&nbsp;&nbsp;</i>
                                        </td>
                                        <td style="color:#ffa800;width:63%;text-align:left;" class="ng-binding" id="nb=1">
                                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td class="european_8_3">
                                    <div class="font_002">
                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                    </div>
                                  </td>
                                  <td class="european_9_3">
                                    <table width="100%">
                                      <tbody>
                                      <tr>
                                        <td style="width:43%;">
                                          <i class="fa fa-fw" style="text-align:right;">&nbsp;&nbsp;</i>
                                        </td>
                                        <td style="color:#ffa800;width:63%;text-align:left;" class="ng-binding" id="nb=2">
                                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td class="sports_10_4" v-if="game.foldersCnt.cnt === 0">
                                    <table width="100%">
                                      <tbody>
                                      <tr>
                                        <td style="width:43%;">
                                          <i class="fa fa-fw" style="text-align:right;">&nbsp;&nbsp;</i>
                                        </td>
                                        <td style="color:#ffa800;width:63%;text-align:left;" class="ng-binding" id="nb=3">
                                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td class="sports_10_4" v-else>
                                    <table width="100%">
                                      <tbody>
                                      <tr>
                                        <td class="sports_10_4" @click="toggleDetail(game.id)">
                                          +{{ game.foldersCnt.cnt }}
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>

                                </template>
                                <template v-else-if="game.folders.length === 0 && game.foldersCnt.cnt > 0">
                                  <td class="european_7_3">
                                    <table width="100%">
                                      <tbody>
                                      <tr class="ng-hide">
                                      <tr>
                                        <td style="width:43%;">
                                          <i class="fa fa-fw" style="text-align:right;">&nbsp;&nbsp;</i>
                                        </td>
                                        <td style="color:#ffa800;width:63%;text-align:left;" class="ng-binding" id="nb=4">
                                          <font-awesome-icon icon="fs fa-circle-plus"/>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td class="european_8_3">
                                    <div class="font_002">
                                      <font-awesome-icon icon="fs fa-circle-plus"/>
                                    </div>
                                  </td>
                                  <td class="european_9_3">
                                    <table width="100%">
                                      <tbody>
                                      <tr>
                                        <td style="width:43%;">
                                          <i class="fa fa-fw" style="text-align:right;">&nbsp;&nbsp;</i>
                                        </td>
                                        <td style="color:#ffa800;width:63%;text-align:left;" class="ng-binding" id="nb=5">
                                          <font-awesome-icon icon="fs fa-circle-plus"/>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td class="sports_10_4">
                                    <table width="100%">
                                      <tbody>
                                      <tr>
                                        <td class="sports_10_4" @click="toggleDetail(game.id)">
                                          <font-awesome-icon icon="fs fa-circle-plus"/>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </template>
                                <template v-else-if="game.folders.length === 0 && game.foldersCnt.cnt === 0">
                                  <td class="european_7_3">
                                    <table width="100%">
                                      <tbody>
                                      <tr class="ng-hide">
                                      <tr>
                                        <td style="width:43%;">
                                          <i class="fa fa-fw" style="text-align:right;">&nbsp;&nbsp;</i>
                                        </td>
                                        <td style="color:#ffa800;width:63%;text-align:left;" class="ng-binding" id="nb=6">
                                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td class="european_8_3">
                                    <div class="font_002">
                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                    </div>
                                  </td>
                                  <td class="european_9_3">
                                    <table width="100%">
                                      <tbody>
                                      <tr>
                                        <td style="width:43%;">
                                          <i class="fa fa-fw" style="text-align:right;">&nbsp;&nbsp;</i>
                                        </td>
                                        <td style="color:#ffa800;width:63%;text-align:left;" class="ng-binding"  id="nb=7">
                                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td class="sports_10_4" >
                                    <table width="100%">
                                      <tbody>
                                      <tr>
                                        <td style="width:43%;">
                                          <i class="fa fa-fw" style="text-align:right;">&nbsp;&nbsp;</i>
                                        </td>
                                        <td style="color:#ffa800;width:63%;text-align:left;" class="ng-binding" id="nb=8">
                                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </template>
                                <template v-else>
                                  <td class="european_7_3"
                                      :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Home'))}"
                                      @click="addCart(game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Home'), game.folders[0], game)"
                                  >
                                    <table width="100%">
                                      <tbody>
                                      <tr class="ng-hide"
                                      >
                                      <tr>
                                        <td style="width:35%;"><i class="fa fa-fw" style="text-align:right;">&nbsp;</i>
                                        </td>
                                        <td
                                            class="ng-binding" id="nb=9">
                                          {{
                                            Number(game.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Home').price).toFixed(2)
                                          }}
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td class="european_8_3"
                                      v-if="game.folders[0].bets.find(e => e.name === 'X'|| e.name === '12' || e.name === 'Away Goal')"
                                      :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal'))}"
                                      @click="addCart(game.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal'), game.folders[0], game)"
                                  >
                                    <div class="ng-binding">
                                      {{
                                        Number(game.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal').price).toFixed(2)
                                      }}
                                    </div>
                                  </td>
                                  <td class="european_8_3" v-else>
                                    <div class="font_002">
                                      <b>-</b>
                                    </div>
                                  </td>
                                  <td class="european_9_3"
                                      :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'Away'))}"
                                      @click="addCart(game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'Away'), game.folders[0], game)"
                                  >
                                    <table width="100%">
                                      <tbody>
                                      <tr>
                                        <td style="width:35%;"><i class="fa fa-fw" style="text-align:right;">&nbsp;</i>
                                        </td>
                                        <td class="ng-binding" id="nb=10">
                                          {{
                                            Number(game.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'Away').price).toFixed(2)
                                          }}
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td class="sports_10_4" @click="toggleDetail(game.id)">
                                    +{{ game.foldersCnt.cnt || 0 }}
                                  </td>
                                </template>
                                <!-- 배당노출 -->
                              </tr>
                              </tbody>
                            </table>
                            <!-- 디테일 -->
                            <transition name="fade" :key="'transitionGame'+index">
                              <Detail
                                  v-if="checkArray(game.id)"
                                  :game="game"
                                  :key="'gameDetail' + index"
                                  :add-cart="addCart">
                              </Detail>
                            </transition>
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <SelectedGame v-else :game="selectedGame" :add-cart="addCart" :check-cart="checkCart" ></SelectedGame>
        </td>
        <td v-else>
          <div style="text-align: center;">
            <Loading></Loading>
          </div>
        </td>

        <!-- <td width="260" class="game_right_wrap" valign="top" style="left: calc(73vw + 230px); position: fixed;" v-if="!sticky">
          <NewCart :toggle-stick="toggleStick"></NewCart>
        </td>
        <td width="260" class="game_right_wrap" valign="top" style="left: calc(73vw + 230px); position: absolute;"
            v-else>
          <NewCart :toggle-stick="toggleStick"></NewCart>
        </td> -->
        <NewCart v-if="user"></NewCart>
        <NewCartNotLogin v-else></NewCartNotLogin>
      </tr>
      </tbody>
    </table>
    <!--    <table width="100%" style="margin-top: 50px; width: 88%; margin-left:20px;" v-else>-->
    <!--      <tbody>-->
    <!--        <tr>-->
    <!--        <td width="260" valign="top" style="text-align: center; left:80%; min-width: 260px;">-->
    <!--          <Loading></Loading>-->
    <!--        </td>-->
    <!--        &lt;!&ndash; left &ndash;&gt;-->
    <!--        <td width="80%" style="padding:0 10px 0 10px; text-align: center;" valign="top">-->
    <!--          <Loading></Loading>-->
    <!--        </td>-->

    <!--        <td width="260" class="game_right_wrap" valign="top" style="left: calc(73vw + 230px); position: absolute;">-->
    <!--          <Loading></Loading>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      </tbody>-->
    <!--    </table>-->
  </section>
  <div v-else>
    <div class="new_page_tab">
      <router-link to="/sport/lsport/prematch" ><span>엘스포츠 유럽형</span></router-link>
      <router-link to="/sport/prematch" class="cho"><span>벳컨 유럽형</span></router-link>
    </div>
    <div class="categorys">
      <button @click="changeCategory(null)" :class="{'active': !this.category}">전체</button>
<!--      <button @click="changeCategory('LINK')" :class="{'active': this.category === 'LINK'}"><img src="@/assets/img/ico/favor_on.png"></button>-->
      <button @click="changeCategory(1)" :class="{'active': this.category === 1}">
        <img src="@/assets/img/ico/1.png"></button>
      <button @click="changeCategory(2)" :class="{'active': this.category === 2}">
        <img src="@/assets/img/ico/2.png">
      </button>
      <button @click="changeCategory(3)" :class="{'active': this.category === 3}">
        <img src="@/assets/img/ico/3.png"></button>
      <!--      <button @click="changeCategory('')"><img src="@/assets/img/ico/4.png"></button>-->
      <button @click="changeCategory(5)" :class="{'active': this.category === 5}">
        <img src="@/assets/img/ico/5.png">
      </button>
      <button @click="changeCategory(6)" :class="{'active': this.category === 6}">
        <img src="@/assets/img/ico/6.png">
      </button>
      <button @click="changeCategory(11)" :class="{'active': this.category === 11}">
        <img src="@/assets/img/ico/11.png">
      </button>
      <button @click="changeCategory(77)" :class="{'active': this.category === 77}">
        <img src="@/assets/img/ico/77.png">
      </button>
      <!--      <button @click="changeCategory('')"><img src="@/assets/img/ico/44.png"></button>-->
    </div>
    <MDate :date-cnt="dateCnt" :payload="payload" :init-folder="initFolder"></MDate>

    <!--    <div class="search-wrap" style="display: block;" v-if="search">-->
    <!--      <div class="in">-->
    <!--        <input type="text" class="inp1" placeholder="검색하실 팀이름을 입력하세요." :value="keyword" v-on:change="searchTeam">-->
    <!--        <button class="btn-srch"></button>-->
    <!--        <button class="btn-close"></button>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- 인디케이터 -->
    <div class="m-game3" v-if="!isLoading" style="min-height: 300px; text-align: center; padding-top: 50px;">
      <Loading></Loading>
    </div>

    <!-- 게임 데이터 -->
    <div class="m-game3" v-if="isLoading && originList.length === 0" style="min-height: 100px; text-align: center;">
      <div class="day">
        <span class="t2" style="text-align: center;">진행중인 경기가 없습니다.</span>
      </div>
    </div>
    <!-- 경기목록   -->
    <div v-else-if="isLoading && originList.length > 0"
         class="m-game"
         v-for="(row, index) in lists"
         :key="'mobileGameList' + index">
      <template v-if="row.isVisible ==='y'">
        <div class="day">
          <span class="t1">{{ row.startTime | formatDate('MM-DD') }} {{ row.startTime | minute }}</span>
          <img v-if="row.sport.icon" :src="row.sport.icon" style="margin-left: 5px; padding-bottom: 5px;">
          <img v-if="row.location.icon"
               :src="row.location.icon"
               style="margin-left: 6px;"
          >
          <span class="t2">
          {{ row.league | leagueName }}
        </span>
          <div class="right">
            <!--  베팅 리밋  -->
            <span></span>
            <a v-if="row.folders.length > 0 && (row.folders[0].isSuspended === 'y' || row.isSuspended === 'y')">
              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
            </a>
            <!--<router-link :to="`/sport/pre/d?gid=${row.id}`" v-else style="color: #bbb">+{{ row.foldersCnt.cnt || 0 }}</router-link>-->
            <a @click="toggleDetail(row.id)" style="color: #bbb">+{{ row.foldersCnt.cnt || 0 }}</a>
          </div>
        </div>
        <div class="team">
          <img @click="addStaredGame(row)" src="@/assets/img/ico/favor_off.png">
          <div class="name">
          <span>
            {{ row.homeTeam | teamName }}
          </span>
            <span>vs</span>
            <span>
            {{ row.awayTeam | teamName }}
          </span>
          </div>
        </div>
        <template v-if="row.folders.length > 0 && (row.folders[0].isSuspended === 'y' || row.isSuspended === 'y' || row.folders[0].bets.find(b => Number(b.price) === 0))">
          <div class="choice">
            <dl>
              <dt>
                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
              </dt>
            </dl>
            <dl>
              <dt>
                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
              </dt>
            </dl>
            <dl>
              <dt>
                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
              </dt>
            </dl>
          </div>
        </template>
        <template v-else-if="row.folders.length === 0 || !row.folders">
          <div class="choice">
            <dl>
              <dt style="text-align: center;">
                <font-awesome-icon icon="fs fa-circle-plus"/>
              </dt>
            </dl>
            <dl>
              <dt style="text-align: center;">
                <font-awesome-icon icon="fs fa-circle-plus"/>
              </dt>
            </dl>
            <dl>
              <dt style="text-align: center;">
                <font-awesome-icon icon="fs fa-circle-plus"/>
              </dt>
            </dl>
          </div>
        </template>
        <template v-else>
          <div class="choice">
            <MPrice
                :bets="row.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Home')"
                :folder="row.folders[0]"
                :add-cart="addCart"
                :check-cart="checkCart"
                :game="row"
            ></MPrice>
            <MPrice
                v-if="row.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                :bets="row.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                :folder="row.folders[0]"
                :add-cart="addCart"
                :check-cart="checkCart"
                :game="row"
            ></MPrice>
            <MPrice
                :bets="row.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'Away')"
                :folder="row.folders[0]"
                :add-cart="addCart"
                :check-cart="checkCart"
                :game="row"
            ></MPrice>
          </div>
        </template>
      </template>
      <RenewalDetail
      v-if="checkArray(row.id)"
      :game="row"
      ></RenewalDetail>
    </div>
    <transition name="fade">
      <MCart v-if="user && this.cartItem.length > 0"></MCart>
    </transition>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import NewCart from '../../../../components/Betting/EuropeanCartNew'
import NewCartNotLogin from '../../../../components/Betting/LsportEuropeanCartNewNotLogin'
import MCart from '../../../../components/Betting/MEuropeanCartNew'

import Detail from '../../../../components/Game/Sports/European/Detail'
import Date from '../../../../components/Game/Sports/European/Date'
import MDate from '../../../../components/Game/Sports/European/MDate'
import LeftSide from '../../../../components/Game/Sports/European/LeftSide'
import MPrice from '../../../../components/Game/Sports/Korean/MPrice'
import Loading from '../../../../components/Loading'
import Catogory from '../../../../components/Game/Sports/European/Catogory'
import SelectedGame from '../../../../components/Game/Sports/European/SelectedGame'
import RenewalDetail from '../../../../components/Game/Sports/European/RenewalEuropeanMobileDetail'

import MSG from '../../../../contants/msg'

import {mapState} from "vuex";

export default {
  name: "Sports",
  components: {
    Date,
    LeftSide,
    NewCart,
    NewCartNotLogin,
    Detail,
    MCart,
    MPrice,
    Loading,
    Catogory,
    MDate,
    SelectedGame,
    RenewalDetail,
  },
  data: function () {
    return {
      type: 'european',
      category: null,
      isLoading: false,
      lists: null,
      sticky: false,
      openDetailGame: [],
      payload: {
        date: moment().tz('Asia/Seoul').format('YYYY-MM-DD')
      },
      selectedGame: null,
    }
  },
  computed: {
    // 마감시간
    endTime: function () {
      return this.$store.state.setting.settings.config.option.gameEndtime.find(e => e.gameName === '스포츠').endTime
    },
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
    europeanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-유럽형').visible
    },
    ...mapState({
      originList: state => state.sports.originList,
      dateCnt: state => state.sports.dateCnt,
      user: state => state.user.user,
      cartItem: state => state.sports.cart,
    }),
  },
  mounted: function () {
    if (!this.europeanVisible){
      this.$store.dispatch('SHOW_ERR_MSG3', '유럽형은 점검중 입니다.')
    }
    this.$store.dispatch('CLEAR_SPORT_CART', '프리매치(유럽형)')
    this.$store.dispatch('GET_DATE_CNT')
    this.initFolder(this.payload)
    this.interval = setInterval(this.removeGames, 1000)
  },
  beforeDestroy: function () {
    clearInterval(this.interval);
  },
  watch: {
    category: function(val){
      this.openDetailGame = [];
      if (!val) return this.lists = this.originList;
      if (Array.isArray(val)) {
        this.lists = this.originList.filter((g) => {
          return val.includes(g.sportId);
        })
        return;
      }
      this.lists = this.originList.filter(e => e.sportId === val);
    }
  },
  methods: {
    initFolder(payload){
      this.isLoading = false;
      this.$store.dispatch('GET_LIST', { payload }).then(() => {
        this.isLoading = true;
        this.lists = this.originList;
      })
    },
    /*
    * 종목의 자세히 버튼
    * **/
    checkArray: function (id) {
      let result = this.openDetailGame.indexOf(id) >= 0
      return result
    },

    /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailGame, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailGame.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailGame = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    checkCart: function (bets, type) {
      if (type === '보너스') {
        return null;
      } else {
        let index = this.cartItem.find(item => item.betsSeq === bets.seq)
        return !!index;
      }
    },
    // 시간이 지난 항목들 안보이게 설정
    removeGames: function () {
      if (this.lists && this.lists.length > 0) {
        let endEvents = this.lists.filter(e => this.$options.filters.gameStarted(e.startTime, this.endTime))
        endEvents.forEach((ele) => {
          let index = this.lists.indexOf(ele)
          if (index >= 0) {
            this.lists.splice(index, 1)
          }
        })
      }

      if (this.cartItem) {
        let endEvents = this.cartItem.filter(e => this.$options.filters.gameStarted(e.startTime, this.endTime))
        endEvents.forEach((ele) => {
          let index = this.cartItem.indexOf(ele)
          if (index >= 0) {
            this.$store.dispatch('DELETE_CART_ITEM_GAME', ele)
          }
        })
      }

    },
    addCart: function (bets, folders, game) {
      if (this.user){
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.유럽형.active) {
          return alert(MSG.authorization);
        }
      } else {
        return alert(MSG.isNotLogin);
      }
      if (bets.name === '') bets.name = bets.displayName;
      const cartItem = {
        gameId: game.id,
        gameSeq: game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: game.sportSeq,
        marketsId: folders.marketSeq,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueNameKr: game.league.nameKr,
        leagueNameEn: game.league.nameEn,
        leagueNameIcon: game.league.icon,
        sportNameKr: game.sport.nameKr,
        sportNameEn: game.sport.nameEn,
        sportNameIcon: game.sport.icon,
        homeNameKr: game.homeTeam.nameKr,
        homeNameEn: game.homeTeam.nameEn,
        awayNameKr: game.awayTeam.nameKr,
        awayNameEn: game.awayTeam.nameEn,
        marketNameKr: folders.market.nameKr,
        marketNameEn: folders.market.nameEn,
        startTime: game.startTime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '프리매치(유럽형)',
        detailType: null,
        currentPeriod: game.currentPeriod,
        currentTime: game.currentTime,
        homeScore: game.homeResult,
        awayScore: game.awayResult,
        penalty: null,
        maxAmount: game.league?.maxAmount
      };

      if (folders.periodSequence !== '0') {
        cartItem.marketNameKr = folders.market.nameKr?.replace('{sw}', folders.periodSequence);
        cartItem.marketNameEn = folders.market.nameEn?.replace('{sw}', folders.periodSequence);
        if (folders.market.nameEn.indexOf('Player Points') >= 0 ||
            folders.market.nameEn.indexOf('Player Rebounds') >= 0 ||
            folders.market.nameEn.indexOf('Player Assists') >= 0 ||
            folders.market.nameEn.indexOf('Player Points and Assists') >= 0 ||
            folders.market.nameEn.indexOf('Player Points and Rebounds') >= 0 ||
            folders.market.nameEn.indexOf('Player Assists and Rebounds') >= 0 ||
            folders.market.nameEn.indexOf('Player Points, Assists and Rebounds') >= 0){
          const playerName = folders.name.split(')')[0];
          cartItem.marketNameKr = `${playerName}) ${cartItem.marketNameKr || cartItem.marketNameEn}`
          cartItem.marketNameEn = `${playerName}) ${cartItem.marketNameEn}`
        }

        if (
          folders.market.nameEn.indexOf('Player Total Kills') >= 0 ||
          folders.market.nameEn.indexOf('Player Total Assists') >= 0
        ){
          const playerName = folders.name.split(' ')[0];
          cartItem.marketNameKr = `${playerName} ${cartItem.marketNameKr || cartItem.marketNameEn}`
          cartItem.marketNameEn = `${playerName} ${cartItem.marketNameEn}`
        }
      }

      if (folders.market.nameEn?.includes('Player To Record a Hit')){
        cartItem.betsName = bets.displayName
      }

      if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
      }

      if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
      }

      this.$store.dispatch('ADD_EUROPEAN_CART_ITEM', cartItem)
    },
    selectGame(game){
      this.selectedGame = game;
    },
    // 게임종목
    changeCategory: function (category) {
      this.category = category;
    },

    // 리그필터
    filterByGames: function (sortType, target) {
      this.openDetailGame = [];
      this.selectedGame = null;
      if (sortType === 'all') {
        return this.lists = this.originList;
      }

      if (sortType === 'league') {
        this.lists = this.originList.filter(e => e.leagueId === Number(target));
      }

      if (sortType === 'date') {
        this.lists = this.originList.filter(e => e.startTime.indexOf(target) >= 0);
      }

      if (sortType === 'sport') {
        this.lists = this.originList.filter(e => e.sportId === target);
      }

      if (sortType === 'team') {
        this.lists = this.originList.filter(e =>
            e.homeTeam.nameEn.indexOf(target) >= 0 ||
            e.homeTeam.namekr.indexOf(target) >= 0 ||
            e.awayTeam.nameEn.indexOf(target) >= 0 ||
            e.awayTeam.namekr.indexOf(target) >= 0
        );
      }
    },
    toggleStick: function () {
      this.sticky = !this.sticky;
    },
    addStaredGame: function (gameSeq) {
      this.$store.dispatch('list/gameStared', gameSeq);
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

.categorys::-webkit-scrollbar{
  display:none;
}
</style>
